"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.constants = void 0;
exports.constants = {
    radiobuttonBorderSpacing: {
        top: 9,
        left: 5
    },
    borderWidth: {
        xs: 1,
        sm: 2,
        md: 3
    },
    // just guessed the borderRadius values to fix lint errors, feel free to change.
    borderRadius: {
        xss: 4,
        md: 40,
        lg: 20
    },
    blockHeight: {
        s0: 6,
        s1: 18,
        s2: 50,
        s3: 140,
        s4: 287,
        s5: 325,
        s6: 337,
        s7: 293,
        s8: 91,
        s9: 136,
        s10: 208,
        s11: 194,
        s12: 27,
        s13: 80,
        s14: 26,
        s15: 56,
        s16: 62,
        s17: 78,
        s18: 44,
        m0: 7,
        m1: 300,
        m2: 400,
        m3: 540,
        m4: 440,
        m5: 541,
        m6: 428,
        m7: 500,
        m8: 204,
        m9: 286,
        m10: 324,
        m11: 302,
        l1: 575,
        l2: 688,
        xl1: 800,
    },
    blockWidth: {
        s0: 328,
        xs: 1,
        sm: 18,
        md: 20,
        lg: 167,
        xl: 185,
        xl1: 600,
        xxl: 568,
        xxl2: 1047,
        s1: 313,
        s2: 108,
        s3: 313,
        s4: 35,
        s5: 71,
        s6: 34,
        s7: 297,
        s8: 382,
        s9: 240,
        s10: 75,
        s11: 45,
        s12: 31,
        s13: 179,
        s14: 130,
        s15: 109,
        s16: 90,
        s17: 110,
        s18: 521,
        s19: 87,
        s20: 138,
        s21: 172,
        s22: 118,
        s23: 164,
        s24: 234,
        s25: 78,
        s26: 97,
        s27: 65,
        s30: 180,
        s31: 65,
        s32: 260,
        s33: 220,
        s34: 76,
        s35: 24,
        s36: 83,
        s37: 70,
        s38: 329,
    },
    paddings: {
        xs: 24,
        sm: 30,
        s: 33,
        s1: 10,
        m1: 66,
        md: 80,
        'md-2': 92,
        md3: 96,
        lg: 100,
        lg1: 128,
        xl: 140,
        s2: 13,
        s3: 32,
        s4: 8,
        s5: 16,
        s6: 14,
        s7: 9,
        s8: 4,
        s9: 6,
        m2: 65,
        m3: 12,
        m4: 72,
        m5: 21,
        m6: 15,
        m7: 25,
        m8: 22,
        m9: 50
    },
    bootstrapContainer: {
        xs: 363,
        sm: 545,
        md: 726,
        lg: 964,
        xl: 1144,
        xxl: 1438
    },
    SelectDropdownHeight: {
        md: 44,
        xxl: 217
    },
    SelectDropdownIconHeight: {
        md: 8,
        lg: 13
    },
    bootstrapGutter: 15,
    contactBlocks: {
        imageSize: {
            sm: 50,
            md: 80
        }
    },
    headerItem: {
        borderHeight: 2,
        logoHeight: 42
    },
    searchWidth: 690,
    searchInputWidth: 447,
    pillWidth: {
        sm: 100,
        md: 150
    },
    zindex: {
        oneStepAbove: 2,
        dropdown: 9,
        nprogressBar: 1000,
        carouselController: 2
    },
    mobileMenu: {
        iconWrapper: 38,
        touchArea: 44,
        itemContainerMinHeight: 60,
        specialSpacing: 6,
        logoHeight: 33,
        cartCircle: {
            sm: 16,
            mb: 19
        }
    },
    productpage: {
        mobileDescHeight: 120,
        buttonContainerWidth: 16,
        sliderHeight: 64,
        sliderDotSize: 12,
        partNumWidth: 120
    },
    roundButtonSize: {
        md: 48
    },
    categories: {
        iconSize: 94
    },
    scrollbarWidth: {
        regular: 17
    },
    filter: {
        maxHeight: 160,
        menuHeight: 52,
        scrollBarWidth: 6
    },
    badgeSize: {
        md: 24
    },
    addToCart: {
        buttonWidth: 130
    },
    listingPage: {
        buttonWidth: 190,
        totalProductsHeight: 30
    },
    descriptionOverlayHeight: {
        md: 35
    },
    searchTab: {
        paddingLeft: 17,
        paddingRight: 18,
        rowGap: 6,
        scrollBarHeight: 6
    },
    smallHorizontalBlock: {
        imageWidth: 208
    },
    loginModalWidth: {
        lg: 568
    },
    myHaldexDropdown: {
        width: 200,
        top: 35,
        arrowTop: 5
    },
    tableRow: {
        paddingHorizontal: 8,
        paddingVertical: 14
    },
    crossReferenceTable: {
        width: 1407
    },
    amountControllerWidth: {
        cartWidth: 103,
        carouselWidth: 151,
        fullWidth: 119
    },
    docTypePill: {
        paddingHorizontal: 10,
        paddingVertical: 6,
        borderRadius: 12
    },
    documentLiteraturePage: {
        imagePaddingVertical: 19,
        imagePaddingHorizontal: 10,
        imageHeight: 290,
        mobileInfoHeight: 81,
        readMeContainerHeight: 93
    },
    searchShowMoreButton: {
        inTabHeight: 44,
        inAllTabHeight: 40,
        paddingHorizontal: 22,
        paddingVertical: 12
    },
    quickOrder: {
        dropdownWidth: 452,
        inputWidthMedium: 132,
        inputWidthSmall: 52
    },
    partnerFinder: {
        dropdownWidth: 226,
        googleSearchWidth: 550,
        height: 730,
        heightMobile: 300,
        gradientWidth: 8,
        searchIconSizeS: 16,
        searchIconSizeM: 20
    },
    puffBlock: {
        arrowHoverMargin: 22,
        imageWidth: 208,
        minColToShowImage: 8
    },
    warrantyPage: {
        warrantyMaxWidth: 958
    },
    grayPuff: {
        arrowHoverMargin: 22,
        imageMaxWidth: 208
    },
    VideoPlayerBlock: {
        paddingBottom: '56.25%',
        playButtonSize: 64,
        playButtonOpacity: 0.1,
        consentTextMaxWidth: 556
    },
    courseInfo: {
        selectMaxWidth: 315
    },
    courseListingCard: {
        minWidth: 146
    },
    carouselController: {
        size: 48,
        offset: -24,
        controllerGapPadding: 24,
        slideDamping: 15,
        controllerTopOffset: 120,
        controllerTopOffsetLg: 150,
        controllerTopOffsetXl: 200
    },
    courseCarouselcard: {
        iconSize: 18,
        imageHeight: 90
    },
    linkBlock: {
        leftPadding: 17,
        rightPadding: 18,
        verticalPadding: 10
    },
    coursePage: {
        coursePageModalWidth: 717,
        coursePageModalHeight: '80%',
        coursePageModalCloseIconTop: 23,
        coursePageModalCloseIconRight: 22,
        highlightBlockTopMargin: 55
    }
};
